'use client'; // Error boundaries must be Client Components
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
export default function Error(_a) {
    var error = _a.error, reset = _a.reset;
    useEffect(function () {
        // Log the error to an error reporting service
        console.error(error);
        Sentry.captureException(error);
    }, [error]);
    return (_jsxs("div", { children: [_jsx("h1", { children: "Something went wrong" }), _jsx("button", { onClick: 
                // Attempt to revovery by trying to re-render the segment
                function () { return reset(); }, children: "Try again" })] }));
}
